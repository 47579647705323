.repository-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  a {
    color: black !important;
    text-decoration: none;
  }
  p {
    width: 100%;
    word-wrap: break-word;
    margin: 0;
  }
  .actions {
    display: flex;
    @media (max-width: 450px) {
      flex-direction: column;
      align-items: center;
      gap: 7px;
    }
  }
}

.repository-card:hover {
  background-color: rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

.card-link {
  width: 85%;
  padding: 15px 10px 0 0;
}

.link-to-github {
  padding: 0 10px 0 10px;
}
.github-icon {
  width: 85px;
}

.description {
  margin: 10px 0;
}

.zap-button-repository {
  svg {
    width: 27px;
    height: 27px;
  }
}

.repository-info-zap-button {
  border: 1px solid #9747ff;
  color: #9747ff;
  border-radius: 10px;
  padding: 5px 10px;
  background: none;
  cursor: default !important;
}

.count-contribution {
  color: #0c6dfd;
}

.tags-container {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 0.9rem;
  .kind-element {
    margin: 0 !important;
    height: 24px;
    display: flex;
    align-items: center;
  }
  .tag-button-repository-element {
    display: flex;
    align-items: center;
    height: 24px;
    white-space: nowrap;
    font-size: 0.9rem;
  }

  .programming-languages-container {
    margin-right: 10px;
    gap: 7px;
    display: flex;
  }
  .license {
    margin-right: 10px;
  }
  .tags {
    display: flex;
    gap: 5px;
  }
}

.tags-container::-webkit-scrollbar {
  display: none;
}

.repository-link-actions-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.repository-card-container {
  display: flex;
  flex-direction: column;
}

.bounty-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  padding: 4px 0;
  p {
    margin: 0;
  }
}

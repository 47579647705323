.review-info {
  a {
    color: black;
  }
  font-size: 1.2rem;
  h3 {
    padding: 5px 0 0 0;
  }
  .app {
    padding: 5px 0 10px 0;
    font-size: 1.5rem;
    img {
      width: 60px;
      height: 60px;
    }
  }
}

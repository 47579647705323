.card-item {
  display: flex;
  gap: 7px;
  padding: 10px;
}
.edit-button {
  cursor: pointer;
}
.link {
  width: 100%;
  text-decoration: none;
  color: black !important;
}

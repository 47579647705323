.count-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 7px 0;
  padding: 4px 12px;
  border-radius: 8px;
}

.zap {
  border: 1px solid #9747ff;
  color: #9747ff;
}

.like {
  border: 1px solid #b32322;
  color: #b32322;
}

.share {
  border: 1px solid #84b7ff;
  color: #84b7ff;
}

.review {
  border: 1px solid #ffc700;
}
.action-buttons-app-info {
  .tooltip-inner {
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 14px;
    white-space: nowrap;
  }
}

.tooltip-zap .tooltip-inner {
  background-color: #9747ff;
  color: #fff;
}

.tooltip-like .tooltip-inner {
  background-color: #b32322;
  color: #fff;
}

.tooltip-share .tooltip-inner {
  background-color: #84b7ff;
  color: rgb(21, 20, 20);
}

.tooltip-review .tooltip-inner {
  background-color: #ffc700;
  color: rgb(21, 20, 20);
}

.delete-review-button {
  border: none;
  outline: none;
  background: none;
  font-weight: 600;
  padding: 0 0 0 4px;
  color: red;
}

.container-button {
  display: flex;
  width: 100%;
  gap: 8px;
  padding: 10px 0;
}

.profile {
  border-radius: 10px !important;
}

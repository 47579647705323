.rating-container {
  display: flex;
  justify-content: center;
  margin: 0;
  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
  }
}

.averate-rating {
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    width: 25px;
    height: 25px;
  }
}

.rating-progressbar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}
.rating-progress-container {
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 450px) {
    width: 70px;
    height: 70px;
  }
}

.averate-rating-container {
  display: flex;
  align-items: center;
}

.Root {
  max-width: 800px;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.highlight:hover {
  background-color: #eee;
}

img.banner {
  border: 1px solid #eee;
  border-radius: 10px;
}
.pointer {
  cursor: pointer;
}

.tab {
  cursor: pointer;
  border: 1px solid #0c6dfd !important;
  margin: 10px 10px 0 0;
}

.repository-view {
  padding: 0 0 0 5px;
  ul {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  ul > li {
    list-style: none;
  }
  .container-name {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    padding: 8px 0;
    h2 {
      margin: 0;
    }
  }
}

.kind-element {
  display: inline-block;
  text-align: center;
  padding: 6px 10px;
  border: 1px solid #0c6dfd;
  color: #0c6dfd;
  border-radius: 5px;
  white-space: nowrap;
}

.description-repository {
  max-width: 550px;
  word-wrap: break-word;
}
.description-issue {
  word-wrap: break-word;
}

.limited-text {
  max-width: 600px;
  word-wrap: break-word;
  a {
    margin-left: 6px;
  }
}

.description {
  word-wrap: break-word;
}

.font-weight-bold {
  font-weight: 600;
  font-size: 1.1rem;
}

.app-info-view {
  @media (max-width: 450px) {
    font-size: 0.8rem !important;
    h6 {
      font-size: 0.9rem !important;
    }
  }
}

.repo-app-info {
  border: 1px solid #dee2e6;
  border-radius: 5px;
  .repository-card {
    width: 100%;
    padding: 15px;
  }
}

.AppInfo {
  @media (max-width: 450px) {
    .lud-16 {
      display: flex;
      align-items: center;
    }
  }
}

.profile {
  display: flex;
  align-items: center;
}

.darked:hover {
  background-color: rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

.multi-value-label-repo-form {
  padding: 5px 5px 5px 10px;
  font-size: 13px;
}

.app-title-on-home-page {
  color: black;
  :hover {
    color: black;
  }
}

.more-button {
  cursor: pointer;
}

.link-to-nostr-info {
  color: #6d757d;
}

.link-to-nostr-info:hover {
  color: #5a5c5e;
}
.tag-event-apps {
  color: #5a5c5e;
  font-size: 0.9rem;
}

.search-field-container {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0 5px;
}

.app-logo-option {
  width: 40px;
  height: 40px;
}
.option-element {
  display: flex;
  align-items: center;
  height: 50px;
  gap: 10px;
  color: black;
}

.default-image-option {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 7px;
  background-color: rgb(225, 225, 225);
  img {
    width: 25px;
    height: 25px;
  }
}

.search-button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 40px;
  @media (max-width: 615) {
    width: 60px;
  }
}

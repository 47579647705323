.app-on-mobile {
  @media (max-width: 450px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.app-container {
  @media (max-width: 450px) {
    min-height: 60vh;
    align-items: center;
  }
}

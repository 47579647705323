.app-profile {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  a {
    color: black !important;
  }
  img {
    width: 45px;
    height: 45px;
  }
  svg {
    width: 45px;
    height: 45px;
    padding: 3px;
  }
}

.count-likes {
  color: #b22222;
  font-weight: 600;
  font-size: 1.1rem;
}

.rating-content-container {
  display: flex;
  justify-content: space-between;
  p {
    margin: 0;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    p {
      margin-bottom: 10px;
    }
  }
}

.app-added-of-the-day {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 7px 10px 3px rgba(34, 60, 80, 0.17);
  -moz-box-shadow: 0px 7px 10px 3px rgba(34, 60, 80, 0.17);
  box-shadow: 0px 7px 10px 3px rgba(34, 60, 80, 0.17);
  border-radius: 15px;
  padding: 5px;
  .inner-block {
    margin: 20px 20px;
  }
}

.default-image {
  margin: 0;
  svg {
    width: 70px;
    height: 70px;
  }
  background-color: white;
}

.counts-app-on-home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-bottom: 5px;
  }
  span {
    font-size: 1rem;
  }
  svg {
    cursor: default !important;
    width: 35px;
    height: 35px;
  }
  div {
    display: flex;
    align-items: center;
    gap: 3px;
  }
}

.container-apps {
  display: flex;
  flex-wrap: wrap;
  padding: 0 auto;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  justify-content: flex-start;
  text-align: center;
  gap: 15px;
  margin-bottom: 22px;
  a {
    text-decoration: none;
    color: black;
  }
  @media (max-width: 995px) {
    justify-content: center;
  }
}

.app-logo-main {
  width: 100px;
  height: 100px;
  border-radius: 20px;
  margin-bottom: 20px;
}

.card-app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: 240px;
  padding: 50px 15px 20px 15px;
  min-height: 350px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 7px 10px 3px rgba(34, 60, 80, 0.17);
  -moz-box-shadow: 0px 7px 10px 3px rgba(34, 60, 80, 0.17);
  box-shadow: 0px 7px 10px 3px rgba(34, 60, 80, 0.17);
  border-radius: 15px;
  transition: 0.3s;
  cursor: pointer;
  p {
    max-width: 100%;
    margin: 0;
    font-size: 1rem;
    word-wrap: break-word;
  }
  .profile {
    width: 100%;
    font-size: 0.95rem;
  }
}

.card-app:hover {
  -webkit-box-shadow: 0px 7px 10px 10px rgba(34, 60, 80, 0.12);
  -moz-box-shadow: 0px 7px 10px 10px rgba(34, 60, 80, 0.12);
  box-shadow: 0px 7px 10px 10px rgba(34, 60, 80, 0.12);
}

.default-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 20px;
  background-color: rgb(225, 225, 225);
  margin-bottom: 20px;
  img {
    width: 50px;
    height: 50px;
  }
}
.default-image-app-review {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 7px;
  background-color: rgb(225, 225, 225);
  img {
    width: 30px !important;
    height: 30px !important;
  }
}

.count-users {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
  span {
    display: flex;
    align-items: center;
    font-weight: 500;
    height: 40px;
  }
}

.tooltip-count-users .tooltip-inner {
  background-color: #9747ff;
  color: #fff;
}
